<template>
  <svg
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M3.99996 8.85669C3.99996 7.75669 4.44663 6.75669 5.17329 6.03002L4.22663 5.08336C3.26663 6.05002 2.66663 7.38336 2.66663 8.85669C2.66663 11.5767 4.69996 13.8167 7.33329 14.1434V12.7967C5.44663 12.4767 3.99996 10.8367 3.99996 8.85669ZM13.3333 8.85669C13.3333 5.91002 10.9466 3.52336 7.99996 3.52336C7.95996 3.52336 7.91996 3.53002 7.87996 3.53002L8.60663 2.80336L7.66663 1.85669L5.33329 4.19002L7.66663 6.52336L8.60663 5.58336L7.88663 4.86336C7.92663 4.86336 7.96663 4.85669 7.99996 4.85669C10.2066 4.85669 12 6.65002 12 8.85669C12 10.8367 10.5533 12.4767 8.66663 12.7967V14.1434C11.3 13.8167 13.3333 11.5767 13.3333 8.85669Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcReset',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
